import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ModeleTerenowe = () => {
  return (
    <Layout>
      <SEO title="Modele Terenowe" />
      <section className="models-top-back"></section>

      <section className="models-content">
        <Link to="/" className="go-back"></Link>
        <div className="container">
          <h2>Terenowe</h2>
          <h4>
            Nasze modele to wierne repliki prawdziwych pogromców terenu. Wielkie
            koła, napę 4x4 i realistyczna konstrukcja pozwalają poczuć nutę
            adrenaliny i ducha jazdy w terenie.
          </h4>
          <p>
            Modele w skali 1:10 są najczęściej spotykanymi w sklepach, jednak te
            używane w Akademii RC są w pełni wyczynowymi egzemplarzami o
            długości 54 cm i szerokości 23cm i prześwicie dochodzącym do 7,5 cm.
            Specjalnie dla nich zbudowaliśmy dowolnie konfigurowalny tor o
            szerokości 4m i długości 8m. Wyposażony w przeszkody wykonane ze
            specjalnie obrobionego metalu i drewna. To sprawia, że tor może być
            wykorzystywany w każdych warunkach. Zarówno wewnątrz sali
            konferencyjnej jak i na świeżym powietrzu. Całość dostarcza mnóstwo
            emocji i radości.
          </p>
          <p>
            Akademia RC dysponuje również modelami w skali 1:24. 22 cm długości,
            10 cm szerokości i 2,5 cm prześwitu sprawia, że te modele wydają się
            być małe i niepozorne, ale potrafią pozytywnie zaskoczyć niejednego
            entuzjastę jazdy w terenie i pokonywania przeszkód. Można je
            potraktować jako rozgrzewkę przed przesiadką na bardziej wymagające
            precyzji i koncentracji modele 1:10, jednak równie dobrze sprawują
            się jako uzupełnienie pełnej gamy modeli terenowych. Dla tych modeli
            Akademia RC przygotowała tor o wymiarach 2x4m z przeszkodami
            wykonanymi ze sklejki malowanej na czarno z surowymi deskami
            zapewnia estetyczny wygląd. Konstrukcja toru oraz modeli umożliwia
            korzystania z atrakcji wewnątrz budynku oraz na zewnątrz.
          </p>
          <div className="models-content--gallery">
            <div className="row">
              <div className="col-sm-6">
                <img
                  className="img-fluid"
                  src={require("../assets/images/model-off-image-1.jpg")}
                  alt=""
                />
              </div>
              <div className="col-sm-6">
                <img
                  className="img-fluid"
                  src={require("../assets/images/model-off-image-2.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
          <h4>
            W zależności od potrzeb klienta jazda z Akademią RC może mieć
            zróżnicowany cel:
          </h4>
          <p>
            <span>Rywalizacja</span> – atrakcja jest prowadzona w formie
            zawodów, podczas których każdy z zawodników może poznać model w
            czasie jazd treningowych z instruktorem, a następnie przystępuje do
            przejazdu z pomiarem czasu,
          </p>
          <p>
            <span>Współpraca</span> – jazda przestaje być atrakcją indywidualną,
            a staje się sportem zespołowym, w którym świetna komunikacja i
            harmonijne współdziałanie to jedyna droga do osiągnięcia sukcesu”
          </p>
          <div className="models-content--gallery">
            <div className="row">
              <div className="col-sm-6">
                <img
                  className="img-fluid"
                  src={require("../assets/images/model-off-image-3.jpg")}
                  alt=""
                />
                <p>
                  <strong>
                    Osiągnięty rezultat wpisywany jest na karcie startowej, a
                    następnie na tablicy wyników.
                  </strong>
                </p>
              </div>
              <div className="col-sm-6">
                <img
                  className="img-fluid"
                  src={require("../assets/images/model-off-image-4.jpg")}
                  alt=""
                />
                <p>
                  <strong>
                    Każdy uczestnik, który brał udział w zawodach dostaje
                    pamiątkowy dyplom, a pierwsza trójka nagradzana jest
                    medalami.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="models-fpv">
        <div className="container">
          <div>
            <h4>Pełne bezpieczeństwo</h4>
            <h2>Maksimum adrenaliny</h2>
            <p>
              Przez zainstalowanie w naszych modelach technlogii FPV możesz
              poczuć się jakbyś siedział w kabinie samochodu. Nowa forma
              sterowania samochodami da Ci jeszcze większą zabawę przy
              pokonywaniu przeszkód!
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ModeleTerenowe
